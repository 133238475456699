import styled from "styled-components"
import { Button } from "react-bootstrap"

export const TopNavBar = styled.nav`
  background: ${props => props.theme.colors.foreground.primary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  min-height: 5vh;
  max-height: 5vh;
  transition: 0.3s;
  padding: 1rem;
  z-index: 10;

  &:hover {
    /* min-height: 8vh; */
  }

  @media screen and (max-width: 768px) {
  }
`
export const BrandMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`
export const NavMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-center;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
    justify-content: flex-start;
  }
`
export const UserMenu = styled.div`
  display: flex;
  width: 10%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`
export const BrandName = styled("span")`
  font-size: ${props => props.theme.font_sizes.h4};
  color: ${props => props.theme.colors.brand.primary};
  text-decoration: none;
  border-style: none;
`

export const WordBank = styled("span")`
  font-size: ${props => props.theme.font_sizes.h4};
  color: ${props => props.theme.colors.text.primary};
`

export const ThemeToggler = styled("label")`
  padding: 1rem;
  margin-top: 0.5rem;
`

export const LogoutButton = styled(Button)`
  color: ${props => props.theme.colors.text.error};
  font-size: ${props => props.theme.font_sizes.title};
  /* background: ${props => props.theme.colors.bg.primary}; */
  border-radius: 4px;
  border-color: ${props => props.theme.colors.text.error};
  transition: 0.3s;
  margin: 0.3rem;
  &:hover {
    transition: 0.5s;
    color: ${props => props.theme.colors.text.inverse};
    font-size: ${props => props.theme.font_sizes.body};
    background: ${props => props.theme.colors.text.error};
  }
`
