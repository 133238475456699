import React from "react"
import { Navigate } from "react-router-dom"

import { useAppSelector } from "../../services/reduxhooks"

type Props = {
  children: React.ReactElement
}

const AdminProtectComponent: React.FC<Props> = ({ children }) => {
  const { user, token } = useAppSelector(state => state.auth)

  if (user && token && user.is_admin) {
    return children
  } else {
  }
  return <Navigate to="/" />
}

export default AdminProtectComponent
