import React from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { FaUserAlt } from "react-icons/fa"

import { MdAdminPanelSettings, MdDashboard } from "react-icons/md"
import { BsFillBackspaceReverseFill } from "react-icons/bs"
import { GiAfrica } from "react-icons/gi"
import { AiFillCar } from "react-icons/ai"
import { HiReceiptTax } from "react-icons/hi"

import { TextComponent } from "../../../../components/typography/TextComponent"
import { useAppSelector } from "../../../../services/reduxhooks"
import { SideNavMenu, SideNavList, SideNavListItem, TitleText, MenuIcon } from "./SideNavStyledComponent"

const home_menu: { icon: React.ReactElement; path: string; title: string; is_admin: boolean; roles: string[] }[] = [
  {
    icon: <MdDashboard />,
    title: "Dashbaord",
    path: "/",
    is_admin: false,
    roles: ["tax_user", "license_user"],
  },
  {
    icon: <AiFillCar />,
    title: "License",
    path: "/license",
    is_admin: false,
    roles: ["tax_user", "license_user"],
  },
  {
    icon: <HiReceiptTax />,
    title: "Taxes",
    path: "/tax",
    is_admin: false,
    roles: ["tax_user", "license_user"],
  },
  {
    icon: <GiAfrica />,
    title: "PAPPS",
    path: "/papps",
    is_admin: false,
    roles: ["tax_user", "license_user"],
  },
  {
    icon: <BsFillBackspaceReverseFill />,
    title: "Reversals",
    path: "/reversal",
    is_admin: false,
    roles: ["tax_user", "license_user"],
  },
  {
    icon: <FaUserAlt />,
    title: "Account",
    path: "/profile",
    is_admin: false,
    roles: ["tax_user", "license_user"],
  },
  {
    icon: <MdAdminPanelSettings />,
    title: "Admin",
    path: "/admin",
    is_admin: true,
    roles: ["tax_user", "license_user"],
  },
]

const SideNavMenuBarComponent: React.FC = () => {
  const { is_admin } = useAppSelector(state => state.auth.user)
  const location = useLocation()

  return (
    <SideNavMenu>
      <TextComponent variant="label">Menu</TextComponent>
      <SideNavList>
        {home_menu.map((item, index) => {
          if (item.is_admin && !is_admin) {
            return null
          } else {
            return (
              <Link to={item.path} style={{ textDecoration: "none" }} key={index}>
                <SideNavListItem key={index} active={item.path === location.pathname}>
                  <MenuIcon active={item.path === location.pathname}> {item.icon} </MenuIcon>
                  <TextComponent variant="label">{item.title}</TextComponent>
                </SideNavListItem>
              </Link>
            )
          }
        })}
      </SideNavList>
    </SideNavMenu>
  )
}

export default SideNavMenuBarComponent
