import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
// import type { ILicensePayment } from "../../../shared/domain/license-payments/LicensePaymentsDTO"

type AccountState = {
  active_account: number
}

const initial_account_state = {
  active_account: 0,
}

const licencePaymentSlice = createSlice({
  name: "license",
  initialState: initial_account_state as AccountState,
  reducers: {
    setActiveAccount: (state, { payload: { active_account } }: PayloadAction<{ active_account: number }>) => {
      state.active_account = active_account
    },
  },
})

export const { setActiveAccount } = licencePaymentSlice.actions

export default licencePaymentSlice.reducer
