import React from "react"
import { Provider } from "react-redux"

import "./App.css"

import store from "./shared/services/store"
import Theme from "./shared/infrastructure/theme"
import { GlobalStyle } from "./shared/components/styled"
import RouteWrapper from "./shared/infrastructure/routes"

function App() {
  return (
    <Provider store={store}>
      <Theme>
        <>
          <GlobalStyle />
          <RouteWrapper />
        </>
      </Theme>
    </Provider>
  )
}

export default App
