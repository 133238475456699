import React from "react"
import { useAppSelector } from "../../services/reduxhooks"
import { ThemeProvider } from "styled-components"
import { dark, light } from "./colors"
import { fonts, font_sizes, font_weights } from "./fonts"
import { sizes } from "./sizes"
import { space, line_heights } from "./spacing"
import device from "./devices"

type Props = {
  children: React.ReactElement
}
const Theme: React.FC<Props> = ({ children }) => {
  const { dark: is_dark } = useAppSelector(state => state.theme)
  const colors = is_dark ? dark : light
  const theme = { colors, device, fonts, font_sizes, font_weights, line_heights, sizes, space }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
