export const fonts = {
  body: "Oswald_400Regular",
  body_two: "Lato_700Bold",
  heading: "Lato_400Regular",
  monospace: "Oswald_400Regular",
  poppins: "Poppins",
}

export const font_weights = {
  regular: 400,
  medium: 500,
  bold: 600,
  bolder: 700,
}

export const font_sizes = {
  icon: "30px",
  caption: "14px",
  button: "16px",
  body: "18px",
  label: "18px",
  title: "22px",
  h5: "24px",
  h4: "34px",
  h3: "45px",
  h2: "56px",
  h1: "112px",
}
