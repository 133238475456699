import "bootstrap/dist/css/bootstrap.css"
import React from "react"
// import { createRoot } from "react-dom/client"
import ReactDOM from "react-dom/client"

import App from "./App"
import "./index.css"

// import reportWebVitals from "./reportWebVitals"
// import { injectReduxStoreToAxios } from "./plugins/APIReqResWithAxios"
// import store from "./services/store"

// injectReduxStoreToAxios(store)


const container = document.getElementById("root") as HTMLElement
const root = ReactDOM.createRoot(container!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
