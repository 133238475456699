import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { IInquireNameRes } from "../../../shared/domain/papps/PappsDTO"

const initial_inquire_name = {
  tran_ref: "",
  sender: {
    inst_id: "",
  },
  receiver: {
    inst_id: "",
    acct_no: "",
    acct_name: "",
    email_address: "",
  },
  status: {
    type: "",
    code: "",
    description: "",
  },
}

const pappsNameSlice = createSlice({
  name: "inquire-name",
  initialState: initial_inquire_name as IInquireNameRes,
  reducers: {
    setInquireName: (state, { payload }: PayloadAction<IInquireNameRes>) => {
      state.receiver = payload.receiver
      state.sender = payload.sender
      state.status = payload.status
      state.tran_ref = payload.tran_ref
    },
  },
})

export const { setInquireName } = pappsNameSlice.actions

export default pappsNameSlice.reducer

// export const selectCurrentUser = (state: RootState) => state.auth.user
