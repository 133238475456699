import styled, { DefaultTheme } from "styled-components"

const defaultTextStyles = (theme: DefaultTheme) => `
  font-family: "${theme.fonts.poppins}";
  font-weight: ${theme.font_weights.regular};
  color: ${theme.colors.text.primary};
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 0px;
`

const h1 = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.h1};
    color:${color}


`
const h2 = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.h2};
    font-family: ${theme.fonts.poppins};
    color:${color}

`
const h3 = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.h3};
    color:${color}


`
const h4 = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.h4};
    font-weight: ${theme.font_weights.regular};
    color:${color}

`

const h5 = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.h5};
    color:${color};
    text-align:${align}


`

const title = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.title};
    font-family: ${theme.fonts.poppins};
    font-weight: ${theme.font_weights.medium};
    color:${color};
    text-align:${align}

    

`

const body = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.caption};
    font-family: ${theme.fonts.poppins};
    color:${color};
    text-align:${align}

`

const hint = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.body};
    font-family: ${theme.fonts.heading};
    color:${color};
    text-align:${align}



`
const disabled = (theme: DefaultTheme, color?: string, align?: string) => `
font-size: ${theme.font_sizes.body};
font-family: ${theme.fonts.poppins};
color: ${theme.colors.text.disabled};
text-align:${align}

`
const error = (theme: DefaultTheme, color?: string, align?: string) => `
    color: ${theme.colors.text.error};
    font-family: ${theme.fonts.poppins};
    text-align:${align}


`
const success = (theme: DefaultTheme, color?: string, align?: string) => `
    color: ${theme.colors.text.success};
    font-family: ${theme.fonts.poppins};
    text-align:${align}

`

const caption = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.caption};
    font-weight: ${theme.font_weights.regular};
    font-family: ${theme.fonts.heading};
    color:${color};
    text-align:${align}

`

const label = (theme: DefaultTheme, color?: string, align?: string) => `
    font-size: ${theme.font_sizes.body};
    font-weight: ${theme.font_weights.medium};
    color:${color};
    text-align:${align}


`

const variants = {
  h1,
  h2,
  h3,
  h4,
  h5,
  title,
  body,
  disabled,
  label,
  caption,
  error,
  success,
  hint,
}
interface ITextComponent {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "title"
    | "body"
    | "disabled"
    | "label"
    | "caption"
    | "error"
    | "success"
    | "hint"
  color?: string
  align?: "left" | "center" | "right"
}
export const TextComponent = styled("span")<ITextComponent>`
  ${({ theme }) => defaultTextStyles(theme)}
  ${({ variant, theme, color, align }) => variants[variant](theme, color, align)}
`

TextComponent.defaultProps = {
  variant: "body",
}
