import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const { REACT_APP_INBOUND_API_URL } = process.env;

const inboudBaseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_INBOUND_API_URL,
    prepareHeaders: (headers, { getState}) => {
        console.log("🚀 ~ file: inboundApi.ts:8 ~ headers:", headers)
        return headers;
    },
    timeout: 6000,
});

const inboudBaseQueryWithRetry = retry(inboudBaseQuery, { maxRetries: 0 });

export const inboundAPI = createApi({
    reducerPath: "inboundTransfers",
    baseQuery: inboudBaseQueryWithRetry,
    refetchOnReconnect: true,
    tagTypes: ["Papps"],
    endpoints: () => ({}),
});
