import React from "react"
import { BrowserRouter as Router, useRoutes, Link } from "react-router-dom"
import Loader from "../../components/utils/Loader"
import AuthProtectComponent from "../../components/protections/AuthProtectedComponent"
import AdminProtectComponent from "../../components/protections/AdminProtectedComponent"
import LayoutStructureComponent from "../layout/index"

const { REACT_APP_BASENAME } = process.env

const dynamicImport = (page: string): React.FC => {
  return React.lazy(() =>
    import(`../../../pages/${page}`)
      .then(p => {
        return p
      })
      .catch(err => console.log(err))
  )
}

const AdminPage = dynamicImport("admin")
const DashboardPage = dynamicImport("home")
const ForgotPasswordPage = dynamicImport("auth/ForgotPasswordPage")
const LicensePaymentsPage = dynamicImport("license")
const LoginPage = dynamicImport("auth/LoginPage")
const ProfilePage = dynamicImport("users")
const ResetPasswordPage = dynamicImport("auth/ResetPasswordPage")
const ReversalPage = dynamicImport("reversals")
const TaxPaymentsPage = dynamicImport("tax")
const PappsPage = dynamicImport("papps")

const AppRoutesConfiguration = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <React.Suspense fallback={<Loader />}>
          <AuthProtectComponent>
            <LayoutStructureComponent>
              <DashboardPage />
            </LayoutStructureComponent>
          </AuthProtectComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/auth",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<Loader />}>
              <LoginPage />
            </React.Suspense>
          ),
        },
        {
          path: "/auth/password/forgot",
          element: (
            <React.Suspense fallback={<Loader />}>
              <ForgotPasswordPage />
            </React.Suspense>
          ),
        },
        {
          path: "/auth/password/reset",
          element: (
            <React.Suspense fallback={<Loader />}>
              <ResetPasswordPage />
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <React.Suspense fallback={<Loader />}>
          <AuthProtectComponent>
            <AdminProtectComponent>
              <LayoutStructureComponent>
                <AdminPage />
              </LayoutStructureComponent>
            </AdminProtectComponent>
          </AuthProtectComponent>
        </React.Suspense>
      ),
      children: [
        {
          path: "/admin/users",
          element: (
            <React.Suspense fallback={<Loader />}>
              <AuthProtectComponent>
                <AdminPage />
              </AuthProtectComponent>
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/license",
      element: (
        <React.Suspense fallback={<Loader />}>
          <AuthProtectComponent>
            <LayoutStructureComponent>
              <LicensePaymentsPage />
            </LayoutStructureComponent>
          </AuthProtectComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/tax",
      element: (
        <React.Suspense fallback={<Loader />}>
          <AuthProtectComponent>
            <LayoutStructureComponent>
              <TaxPaymentsPage />
            </LayoutStructureComponent>
          </AuthProtectComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/papps",
      element: (
        <React.Suspense fallback={<Loader />}>
          <AuthProtectComponent>
            <LayoutStructureComponent>
              <PappsPage />
            </LayoutStructureComponent>
          </AuthProtectComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/reversal",
      element: (
        <React.Suspense fallback={<Loader />}>
          <AuthProtectComponent>
            <LayoutStructureComponent>
              <ReversalPage />
            </LayoutStructureComponent>
          </AuthProtectComponent>
        </React.Suspense>
      ),
    },
    {
      path: "/profile",
      element: (
        <React.Suspense fallback={<Loader />}>
          <AuthProtectComponent>
            <LayoutStructureComponent>
              <ProfilePage />
            </LayoutStructureComponent>
          </AuthProtectComponent>
        </React.Suspense>
      ),
    },

    {
      path: "*",
      element: <NoMatch />,
    },
  ])

  return routes
}

const RouteWrapper = () => {
  return (
    <Router basename={REACT_APP_BASENAME}>
      <AppRoutesConfiguration />
    </Router>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}
export default RouteWrapper
