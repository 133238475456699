import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { IRateInquiryRes } from "../../../shared/domain/papps/PappsDTO"

const initial_inquire_state = {
    time: "",
    sender_amount: "",
    papss_fee: "",
    bank_fee: "",
    total_amount: "",
    receiver_amount: "",
    sender_currency: "",
    receiver_currency: "",
    sender_to_receiver_rate: "",
    sender_to_usd: "",
    receiver_to_usd: "",
    usd_amount: "",
    usd_fee: "",
}

const pappsRateSlice = createSlice({
  name: "inquire-rate",
  initialState: initial_inquire_state as IRateInquiryRes,
  reducers: {
    setInquireRate: (state, { payload }: PayloadAction<IRateInquiryRes>) => {
      state.time = payload.time
      state.sender_amount = payload.sender_amount
        state.papss_fee = payload.papss_fee
        state.bank_fee = payload.bank_fee
        state.total_amount = payload.total_amount
        state.receiver_amount = payload.receiver_amount
        state.sender_currency = payload.sender_currency
        state.receiver_currency = payload.receiver_currency
        state.sender_to_receiver_rate = payload.sender_to_receiver_rate
        state.sender_to_usd = payload.sender_to_usd
        state.receiver_to_usd = payload.receiver_to_usd
        state.usd_amount = payload.usd_amount
        state.usd_fee = payload.usd_fee
    },
  },
})

export const { setInquireRate } = pappsRateSlice.actions

export default pappsRateSlice.reducer

// export const selectCurrentUser = (state: RootState) => state.auth.user
