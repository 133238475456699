import React from "react"
import { FaPowerOff, FaRegLightbulb, FaMoon } from "react-icons/fa"
import { Link } from "react-router-dom"
import Switch from "react-switch"

import { setTheme } from "../../../../services/theme/themeSlice"
import { setLogout } from "../../../../../pages/auth/services/authSlice"
import { TopNavBar, BrandMenu, BrandName, WordBank, UserMenu, LogoutButton, ThemeToggler } from "./HeaderStyles"
import { useAppDispatch, useAppSelector } from "../../../../services/reduxhooks"

const HeaderComponent = () => {
  const dispatch = useAppDispatch()

  const { dark } = useAppSelector(state => state.theme)

  const logoutHandler = () => {
    dispatch(setLogout())
  }

  const themeMoodHandler = (_mood: boolean) => {
    dispatch(setTheme({ theme: _mood }))
  }

  return (
    <header>
      <TopNavBar>
        <BrandMenu>
          <Link to="/" style={{ textDecoration: "none" }}>
            <BrandName>AGI</BrandName>
            <WordBank>Bank</WordBank>
            &nbsp;
            <span style={{ color: "#000" }}>payment</span>
          </Link>
        </BrandMenu>
        <UserMenu>
          <ThemeToggler>
            <Switch
              height={35}
              width={75}
              checked={dark}
              onColor={"#fff"}
              offColor={"#000"}
              onHandleColor="#616f39"
              offHandleColor="#616f39"
              uncheckedIcon={<FaMoon color="#fff" />}
              checkedIcon={<FaRegLightbulb color="#f9a825" />}
              onChange={e => {
                themeMoodHandler(e)
              }}
            />
          </ThemeToggler>
          <LogoutButton onClick={logoutHandler} size="sm" variant="outline-danger">
            <FaPowerOff />
          </LogoutButton>
        </UserMenu>
      </TopNavBar>
    </header>
  )
}

export default HeaderComponent
