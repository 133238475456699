import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

import authReducer from "../../pages/auth/services/authSlice"
import inquireRateReducer from "../../pages/papps/services/pappsRateSlice"
import inquireNameReducer from "../../pages/papps/services/pappsNameSlice"
import transferFundsReducer from "../../pages/papps/services/pappsTransferSlice"
import licencePaymentReducer from "../../pages/license/services/licensePaymentSlice"
import themeReducer from "./theme/themeSlice"
import { baseAPI } from "./baseAPI"
import { inboundAPI } from "./inboundAPI"
// addPartner: addPartnerReducer,

const persistAuth = {
  key: "auth",
  storage,
}
const persistTheme = {
  key: "theme",
  storage,
}

const root_reducer = {
  auth: persistReducer(persistAuth, authReducer),
  theme: persistReducer(persistTheme, themeReducer),
  license: licencePaymentReducer,
  inquireName: inquireNameReducer,
  inquireRate: inquireRateReducer,
  transferFunds: transferFundsReducer,
  [baseAPI.reducerPath]: baseAPI.reducer,
  [inboundAPI.reducerPath]: inboundAPI.reducer,
}

const store = configureStore({
  reducer: root_reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseAPI.middleware, inboundAPI.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export default store
