import React from "react"
import { MainBody } from "../../components/styled"
import HeaderComponent from "./components/header/HeaderComponent"
import SideNavMenuBarComponent from "./components/side-nav"

type Props = {
  children: React.ReactElement
}

const LayoutStructureComponent: React.FC<Props> = ({ children }) => {
  return (
    <>
      <HeaderComponent />
      <SideNavMenuBarComponent />
      <MainBody>{children}</MainBody>
    </>
  )
}

export default LayoutStructureComponent
