import React from "react"
import { useLocation, Navigate } from "react-router-dom"

import { useAppSelector } from "../../services/reduxhooks"

type Props = {
  children: React.ReactElement
}

const AuthProtectComponent: React.FC<Props> = ({ children }) => {
  const { token } = useAppSelector(state => state.auth)
  const location = useLocation()

  if (!token) {
    return <Navigate to="/auth" state={{ from: location }} replace />
  }

  return children
}

export default AuthProtectComponent
