import styled, { createGlobalStyle } from "styled-components"
import { FaFileExport } from "react-icons/fa"

export const GlobalStyle = createGlobalStyle`
 body {
   background: ${props => props.theme.colors.bg.primary};
  }


`
export const MainBody = styled.div`
  position: relative;
  margin-left: 15vw;
  transition: margin-left 0.5s;
  padding: 2rem;
  border-color: grey;
  max-width: 100%;
  min-height: 95vh;
  max-height: 95vh;
`
// export const Card = styled.div`
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//   border-radius: 3px;
//   border: 1px solid ${props => props.theme.colors.brand.primary};
//   padding: 0.5rem;
//   max-width: 100%;
//   margin-bottom: 15px;
//   height: fit-content;
//   transition: 0.2s;
//   /* cursor: pointer; */

//   &:hover {
//     background: ${props => props.theme.colors.foreground.primary};
//   }
// `
export const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  padding: 0.5rem;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: 1rem 0rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px 0 #404b69;
    background-color: #404b69;
    color: #ffffff;
  }
`

export const CustomCard = styled(Card)`
  align-items: center;
  box-shadow: -1px -1px 6px 0 ${props => props.theme.colors.ui.shadow};
  /* border-style: none; */
  border-radius: 3px;
  border-color: grey;
  justify-content: center;
  padding: 1rem;
  transition: 0.3s;
  /* elevation: 4dp; */
  background-color: ${props => props.theme.colors.foreground.primary};
  &:hover {
  }
`

export const DashMenuItemTitle = styled("p")`
  text-align: center;
  text-decoration-style: none;
  color: ${props => props.theme.colors.text.primary};
`

export const AbsoluteCenterContainer = styled.div`
  background-color: ${props => props.theme.colors.brand.primary};
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  padding: 0.1rem;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 3px solid ${props => props.theme.colors.brand.primary};

  @media ${props => props.theme.device.mobile_small} {
  }
  @media ${props => props.theme.device.mobile_medium} {
    max-width: 75vw;
  }
  @media ${props => props.theme.device.mobile_large} {
    max-width: 75vw;
  }
  @media ${props => props.theme.device.tablet} {
    max-width: 75vw;
  }
`
export const CustomIcon = styled("span")`
  color: ${props => props.theme.colors.brand.primary};
  font-size: ${props => props.theme.font_sizes.icon};
`

export const FormInputField = styled.input`
  color: black;
  background-color: white;
  width: 100%;
  height: 5vh;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: light;
  font-family: sans-serif, Helvetica, Arial;
  border-radius: 4px;
  border: 0.1rem solid ${props => props.theme.colors.brand.primary};
  transition: 0.2s;

  &:focus {
    border: 2px solid green;
  }
`

export const FormSubmitButton = styled.button`
  color: ${props => props.theme.colors.text.primary};
  font-size: 16px;
  font-family: sans-serif, Helvetica, Arial;
  border-radius: 5px;
  border: 2px solid ${props => props.theme.colors.brand.primary};
  transition: 0.8s;
  width: 100%;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.theme.colors.brand.primary};
    color: white;
    font-size: 24px;
  }
`
export const HorizontalLine = styled("hr")`
  color: ${props => props.theme.colors.brand.primary};
`

export const HeaderTextFour = styled("h4")`
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.font_sizes.h4};
`

const primary_color = "#616f39"

export const AbsoluteCenter = styled("div")`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 25vw;
  height: max-content;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #616f39;

  @media ${"prosp=>props. device.mobileL"} {
    max-width: 75vw;
  }
  @media ${"device.mobileM"} {
    max-width: 75vw;
  }
  @media ${"device.mobileS"} {
    max-width: 75vw;
  }
  @media ${"device.tablet"} {
    max-width: 75vw;
  }
`
export const AgibLogo = styled.img`
  align-content: center;
  margin-bottom: 1rem;
  width: 100%;
  border: 0;
  // background-color: #ffffff;
  // background-color: #616f39;
`
export const LoginButton = styled.button`
  color: #616f39;
  font-size: 16px;
  font-family: sans-serif, Helvetica, Arial;
  border-radius: 5px;
  border: 2px solid #616f39;
  transition: 0.8s;

  &:hover {
    background-color: #616f39;
    color: white;
    font-size: 24px;
  }
`
export const InputField = styled.input`
  color: black;
  background-color: white;
  width: 100%;
  height: 5vh;
  padding: 1rem;
  margin: 0.2rem 0rem;
  font-size: 16px;
  font-weight: light;
  font-family: sans-serif, Helvetica, Arial;
  border-radius: 5px;
  border: 0.01rem solid silver;
  transition: 0.2s;

  &:focus {
    border: 2px solid green;
  }
`
export const InputFieldBDL = styled.input`
  color: black;
  background-color: white;
  width: 100%;
  height: 5vh;
  padding: 1rem;
  margin: 0.2rem 0rem;
  font-size: 16px;
  font-weight: light;
  font-family: sans-serif, Helvetica, Arial;
  border: none;
  border-bottom: 0.09rem solid ${primary_color};
  transition: 0.2s;

  &:focus {
    border: none;
    border: 0.09rem solid ${primary_color};
  }
`

export const ErrorMessage = styled.h5`
  color: white;
  background-color: #f44336;
  padding: 0.5rem;
`
export const DashboardMainContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 3rem auto;
  max-width: 95vw;
  padding: 2rem;
  border-radius: 2px;
`

export const Feed = styled.div`
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid silver;
`

export const IconMenuContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-width: 100%;
  min-height: 7vh;
  max-height: 7vh;
  transition: 0.3s;
  padding: 0.4rem;
  // background-color: red;
  // z-index: 10;

  @media screen and (max-width: 768px) {
  }
`

export const CustomFileExport = styled(FaFileExport)`
  font-size: 1.7rem;
  border-radius: 5px;
  color: ${primary_color};
`

export const BrandButton = styled("button")`
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.colors.brand.primary};
  border-radius: 4px;
  color: ${props => props.theme.colors.brand.primary};
  margin: 0.5rem 0rem;
  padding: 0.3rem;
  width: fit-content;
  transition: 1s;
  &:hover {
    background-color: ${props => props.theme.colors.brand.primary};
    color: ${props => props.theme.colors.ui.tertiary};
  }
`
