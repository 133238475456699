import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { IAuth } from "../../../shared/domain/auth/AuthDTOs"

type AuthState = {
  user: IAuth
  token: string | null
  is_logged_in: boolean
}
const initial_user_state = {
  id: 0,
  email: "",
  is_admin: false,
  is_active: false,
  first_name: "",
  last_name: "",
  title: "",
  phone: 0,
  accounts: [],
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: initial_user_state,
    token: null,
    is_logged_in: false,
  } as AuthState,
  reducers: {
    setLogout: state => {
      state.is_logged_in = false
      state.user = initial_user_state
      state.token = ""
    },
    setCredentials: (
      state,
      { payload: { user, token, is_logged_in } }: PayloadAction<{ user: IAuth; token: string; is_logged_in: boolean }>
    ) => {
      state.user = user
      state.token = token
      state.is_logged_in = is_logged_in
    },
  },
})

export const { setCredentials, setLogout } = authSlice.actions

export default authSlice.reducer

// export const selectCurrentUser = (state: RootState) => state.auth.user
