import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type {
  IInquireNameRes,
  IRateInquiryRes,
  ITransferFunds,
  ITransferFundsRes,
} from "../../../shared/domain/papps/PappsDTO"

const initial_transfer_state = {
  inquire_name: {
    tran_ref: "",
    sender: {
      inst_id: "",
    },
    receiver: {
      inst_id: "",
      acct_no: "",
      acct_name: "",
      email_address: "",
    },
    status: {
      type: "",
      code: "",
      description: "",
    },
  },
  inquire_rate : {
    time: "",
    sender_amount: "",
    papss_fee: "",
    bank_fee: "",
    total_amount: "",
    receiver_amount: "",
    sender_currency: "",
    receiver_currency: "",
    sender_to_receiver_rate: "",
    sender_to_usd: "",
    receiver_to_usd: "",
    usd_amount: "",
    usd_fee: "",
  },
  transfer_funds: {
    tran_ref: "",
    tran_date_time: "",
    orgnl_txn_info: {
      tran_ref: "",
      endtoend_ref: "",
      status: {
        type: "",
        reason: "",
      },
    },
    status: {
      type: "",
      code: "",
      description: "",
    },
  },
  amount: "",
  institute_bic: "",
  payment_type: "",
  local_instrument_type: "",
}

const pappsTransferSlice = createSlice({
  name: "transfer-fund",
  initialState: initial_transfer_state as ITransferFunds,
  reducers: {
    setInquireName: (state, { payload }: PayloadAction<IInquireNameRes>) => {
      state.inquire_name = payload
    },
    setInquireRate: (state, { payload }: PayloadAction<IRateInquiryRes>) => {
      state.inquire_rate = payload
    },
    setPays: (
      state,
      {
        payload: { amount, payment_type, local_instrument_type },
      }: PayloadAction<{
        amount: string
        payment_type: "INVOICE" | "DIRECT" | string
        local_instrument_type: "ET" | "SSA3" | "USDP" | string
      }>
    ) => {
      state.amount = amount
      state.payment_type = payment_type
      state.local_instrument_type = local_instrument_type
    },
    setInstituteBIC: (
      state,
      {
        payload: { institute_bic },
      }: PayloadAction<{
        institute_bic: string
      }>
    ) => {
      state.institute_bic = institute_bic
    },
    setTransferFund: (state, { payload }: PayloadAction<ITransferFundsRes>) => {
      state.transfer_funds = payload
    },
    clearTransferFund: (state, { payload }: PayloadAction<{}>) => {
      state.inquire_rate = initial_transfer_state.inquire_rate
      state.inquire_name = initial_transfer_state.inquire_name
      state.transfer_funds = initial_transfer_state.transfer_funds
    },
  },
})

export const { setInquireName, setInquireRate, setPays, setInstituteBIC, setTransferFund, clearTransferFund } =
  pappsTransferSlice.actions

export default pappsTransferSlice.reducer

// export const selectCurrentUser = (state: RootState) => state.auth.user
